<template>
  <v-scale-transition>
    <kits-panel
      :kits-items="monthlyExcerptList"
      :breadcrumbs="breadcrumbs"
    >
      <h3 class="headline grey--text text-xs-center hidden-sm-and-down">
        Monthly Excerpt
      </h3>
    </kits-panel>
  </v-scale-transition>
</template>

<script>
const formTitle = 'Monthly Excerpt'

export default {
  components: {
    KitsPanel: () => import(/* webpackChunkName: "kitsPanel" */ '@/components/KitsPanel'),
  },
  data () {
    return {
      breadcrumbs: [
        {
          text: 'Management Report', disabled: false, to: '/management/management_kits',
        },
        {
          text: formTitle, disabled: true,
        },
      ],
      monthlyExcerptList: [
        {
          icon: 'mdi-clipboard-account',
          path: '/management/monthly_activation',
          title: 'Monthly Activation',
          desc: 'Monthly activation report.',
        },
        {
          icon: 'mdi-account-star',
          path: '/management/monthly_dealer_kpi',
          title: 'Dealer KPI',
          desc: 'Monthly dealer KPI report.',
        },
        {
          icon: 'mdi-currency-usd',
          path: '/management/floating_erecharge',
          title: 'Floating Erecharge',
          desc: 'Monthly Floating Erecharge.',
        },
        /*
        {
          icon: 'mdi-account-star',
          path: '/management/super_dealer',
          title: 'Super Dealer',
          desc: 'Super dealer detail list.',
        },
        */
      ],
      clearance: '',
      code: 0,
      height: 'auto',
    }
  },
}
</script>
